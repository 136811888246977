// extracted by mini-css-extract-plugin
export var wrap = "plan-content-module--wrap--DO0dC";
export var header = "plan-content-module--header--1UFEf";
export var headerDecoration = "plan-content-module--headerDecoration--2VPy6";
export var readText = "plan-content-module--readText--1oCtA";
export var title = "plan-content-module--title--Ec7Qj";
export var image = "plan-content-module--image--3zWRe";
export var prices = "plan-content-module--prices--35xgO";
export var apply = "plan-content-module--apply--24Qi8";
export var apply__caution = "plan-content-module--apply__caution--1c6M6";
export var description = "plan-content-module--description--1dJXB";
export var description__blue = "plan-content-module--description__blue--1MB8w";
export var plus = "plan-content-module--plus--2J8FO";
export var basicSupport = "plan-content-module--basicSupport--2HETW";
export var basicSupport__wrap = "plan-content-module--basicSupport__wrap--3-mhc";
export var accordion = "plan-content-module--accordion--3xsyZ";
export var slideIn = "plan-content-module--slideIn--1vaUz";
export var basicContainer = "plan-content-module--basicContainer--1acGY";
export var fullContainer = "plan-content-module--fullContainer--3yV0W";
export var basicContainer__wrapper = "plan-content-module--basicContainer__wrapper--2rlD8";
export var fullContainer__wrapper = "plan-content-module--fullContainer__wrapper--1SoYE";
export var fullContainer__heading = "plan-content-module--fullContainer__heading--2DGg_";
export var supportContainer = "plan-content-module--supportContainer--NxEIx";
export var supportInner = "plan-content-module--supportInner--26mya";
export var supportFlexbox = "plan-content-module--supportFlexbox--1O0et";
export var supportBox = "plan-content-module--supportBox--eFciJ";