import classNames from 'classnames'
import React from 'react'
import * as Styles from './section-title.module.scss'

export function SectionTitle({
  children,
  en,
  as: Tag = 'div',
  subText,
  gradient,
  ...props
}) {
  return (
    <div>
      <p className={Styles.sectionTitle__en}>{en}</p>
      <Tag
        className={classNames(Styles.sectionTitle, gradient && Styles.gradient)}
      >
        {children}
      </Tag>
      {subText && <p className={Styles.subText}>{subText}</p>}
    </div>
  )
}
