// extracted by mini-css-extract-plugin
export var contact = "contact-module--contact--67aL8";
export var contact__title_pc = "contact-module--contact__title_pc--1KMjK";
export var contact__title = "contact-module--contact__title--3i-8Q";
export var contact__title_sp = "contact-module--contact__title_sp--2MecU";
export var contact__start = "contact-module--contact__start--3Zfek";
export var contact__mail = "contact-module--contact__mail--DZQqA";
export var contact__btn = "contact-module--contact__btn--2I3iH";
export var contact__btn_claim = "contact-module--contact__btn_claim--2Xw2C";
export var contact__attention = "contact-module--contact__attention--zH-5w";
export var bgPc = "contact-module--bgPc--mfYKK";
export var bgSp = "contact-module--bgSp--1FlMu";