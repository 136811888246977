import { graphql, useStaticQuery } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'
import { pagesPath } from '../../lib/pagesPath'
import { SectionTitle } from '../general/section-title'
import * as Styles from './faq.module.scss'

export function Faq({ notLink }) {
  const { allMicrocmsServiceFaq } = useStaticQuery(
    graphql`
      query {
        allMicrocmsServiceFaq(filter: { isTop: { eq: true } }) {
          nodes {
            question
            answer
            serviceFaqId
            category
          }
        }
      }
    `
  )

  return (
    <div className={Styles.faq}>
      <SectionTitle en="FAQ" as="h2" gradient>
        よくある質問
      </SectionTitle>
      <ul className={Styles.faq__box}>
        {allMicrocmsServiceFaq.nodes.map((node) => (
          <li key={node.serviceFaqId}>
            <details>
              <summary>
                <span>
                  <div className={Styles.faq__box_set}>{node.question}</div>
                  <p>＋</p>
                </span>
              </summary>
              <div
                className={Styles.faq__details_content}
                dangerouslySetInnerHTML={{ __html: node.answer }}
              />
            </details>
          </li>
        ))}
      </ul>

      {!notLink && (
        <div className={Styles.faq__btn}>
          <AnchorLink to={pagesPath.faq.$url()}>一覧を見る</AnchorLink>
        </div>
      )}
    </div>
  )
}
