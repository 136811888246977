import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import { withPrefix } from 'gatsby'
import React from 'react'
import { SectionTitle } from '../general/section-title'
import * as Styles from './reason.module.scss'

export function Reason({ ...props }) {
  return (
    <>
      <section id="reason" className={Styles.reason}>
        <div className={Styles.reasonTitle}>
          <SectionTitle
            as="h2"
            en="REASON"
            subText={
              <>
                <span>グロースからプライム市場まで</span>
                <br className="sp" />
                様々な企業に選ばれる理由とは？
              </>
            }
          >
            <img
              src={withPrefix('/service_logo.svg')}
              className="logo"
              alt="Uniforce(ユニフォース)クラウド決算開示管理サービス"
            />
            が選ばれる理由
          </SectionTitle>
        </div>

        <div className={Styles.reasons}>
          <div className={Styles.reasonItem}>
            <div className={Styles.reasonDescription}>
              <h3>初期導入が簡単</h3>
              <div className={classNames(Styles.reasonImage, 'sp')}>
                <StaticImage
                  src="../../images/reason_image_2.png"
                  alt="業務負荷・ミス軽減が軽減"
                  height={234.52}
                />
              </div>
              <p>
                一般的なプロダクト管理ツールでは、一からタスクやスケジュールを入力する必要があるため導入工数がかかってしまうが、自動作成機能のおかげで導入が簡単で、かつ運用についても開示業務に特化しているためツールの定着も早かった。
              </p>
            </div>
            <div className={classNames(Styles.reasonImage, 'pc')}>
              <StaticImage
                src="../../images/reason_image_2.png"
                alt="業務負荷・ミスが軽減"
                height={275}
              />
            </div>
          </div>

          <div className={Styles.reasonItem}>
            <div className={Styles.reasonDescription}>
              <h3>業務負荷・ミスが軽減</h3>
              <div className={classNames(Styles.reasonImage, 'sp')}>
                <StaticImage
                  src="../../images/reason_image_1.png"
                  alt="初期導入が簡単"
                  height={239}
                />
              </div>
              <p>
                業務の効率化はもちろん、正確性と納期厳守が求められる業務の性質上、修正・確認作業など煩雑で面倒な作業について、ミスの発生リスクが軽減されることで、担当者のストレス軽減につながっている。
              </p>
            </div>
            <div className={classNames(Styles.reasonImage, 'pc')}>
              <StaticImage
                src="../../images/reason_image_1.png"
                alt="初期導入が簡単"
                height={281}
              />
            </div>
          </div>

          <div className={Styles.reasonItem}>
            <div className={Styles.reasonDescription}>
              <h3 className={Styles.centerHeading}>
                効率化により空いた工数で
                <br className="sp" />
                開示情報の改善に
              </h3>
              <div className={classNames(Styles.reasonImage, 'sp')}>
                <StaticImage
                  src="../../images/reason_image_3.png"
                  alt="効率化により空いた工数で開示情報の改善に"
                  height={240.08}
                />
              </div>
              <p>
                開示情報による企業価値向上に取り組むだけでなく、担当者の人事評価において、決まった業務を正確にこなすルーティンワークの減点式の評価だけでなく、企画提案や改善業務により加点評価がしやすくなった。
              </p>
            </div>
            <div className={classNames(Styles.reasonImage, 'pc')}>
              <StaticImage
                src="../../images/reason_image_3.png"
                alt="効率化により空いた工数で開示情報の改善に"
                height={281}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
